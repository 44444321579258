<template>
  <v-card class="form_container pa-4 mt-4" max-width="600">
    <v-tabs centered grow v-model="tab">
      <v-tab key="0">INICIO DE SESIÓN</v-tab>
      <v-tab key="1">REGISTRO</v-tab>
      <v-tab v-show="recover.active" key="2">Recuperación</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item key="0">
        <v-form
          v-model="login.validation"
          ref="loginForm"
          @submit.prevent="Login"
          class="pa-4"
        >
          <v-alert type="error" v-if="login.error"
            >Los datos de acceso no son correctos</v-alert
          >
          <v-alert type="error" v-if="login.cuentaDesactivada"
            >Tu cuenta ha sido desactivada, si crees que esto es un error envía
            un correo a info@turismoyrelgion.es</v-alert
          >
          <v-text-field
            v-model="login.email"
            type="email"
            name="correo"
            filled
            label="Email"
            :rules="[rules.req, rules.email]"
          ></v-text-field>
          <v-text-field
            v-model="login.password"
            name="contraseña"
            filled
            label="Contraseña"
            :rules="[rules.min, rules.req, rules.containsNum]"
            :type="login.showPass ? 'text' : 'password'"
            :append-icon="login.showPass ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="login.showPass = !login.showPass"
          ></v-text-field>
          <div class="d-flex">
            <v-spacer></v-spacer>
            <v-btn
              small
              color="error"
              text
              class="mb-2"
              @click.stop="(recover.active = true), (tab = 2)"
              >He olvidado mi contraseña</v-btn
            >
          </div>
          <v-btn type="submit" large color="primary" block
            >Iniciar sesión</v-btn
          >
        </v-form>
      </v-tab-item>
      <v-tab-item key="1">
        <v-form
          class="pa-4"
          v-model="register.validation"
          ref="registerForm"
          @submit.prevent="Register"
        >
          <v-container>
            <v-alert type="error" v-if="register.enUso"
              >Este correo ya está en uso</v-alert
            >
            <v-text-field
              :rules="[rules.req, rules.email]"
              v-model="register.email"
              name="email"
              filled
              label="Email"
              @change="correoUnico"
            ></v-text-field>

            <v-text-field
              :rules="[rules.req]"
              v-model="register.username"
              name="user"
              filled
              label="Nombre de usuario"
            ></v-text-field>

            <v-dialog max-width="400" v-model="date_active">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  block
                  style="text-transform: none"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-label v-if="register.born == ''"
                    >Selecionar fecha de nacimiento</v-label
                  >
                  <v-label v-else
                    >Fecha seleccionada : {{ register.born }}</v-label
                  >
                </v-btn>
              </template>
              <v-card>
                <v-date-picker
                  :rules="[rules.req]"
                  locale="es-ES"
                  full-width
                  color="primary"
                  v-model="register.born"
                  first-day-of-week="1"
                  :max="new Date().toISOString().slice(0, 10)"
                  scrollable
                ></v-date-picker>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    class="cancel"
                    @click="
                      register.born = null;
                      date_active = false;
                    "
                    >Cancelar</v-btn
                  >
                  <v-btn
                    color="primary"
                    text
                    class="confirm"
                    @click="date_active = false"
                    >Aceptar</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
            <br />
            <br />
            <v-text-field
              :rules="[rules.min, rules.req, rules.containsNum]"
              v-model="register.password"
              name="password"
              filled
              label="Contraseña"
              :type="register.showPass1 ? 'text' : 'password'"
              :append-icon="register.showPass1 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="register.showPass1 = !register.showPass1"
            ></v-text-field>
            <v-text-field
              :rules="[
                rules.req,
                register.password === register.password2 ||
                  'Las contraseñas no coinciden',
              ]"
              v-model="register.password2"
              name="password2"
              filled
              label="Confirmar contraseña"
              :type="register.showPass2 ? 'text' : 'password'"
              :append-icon="register.showPass2 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="register.showPass2 = !register.showPass2"
            ></v-text-field>

            <v-checkbox :rules="[rules.req]" v-model="register.politic.checbox">
              <template v-slot:label>
                <span>
                  He leído y acepto la&nbsp;
                  <v-dialog
                    persistent
                    max-width="400px"
                    scrollable
                    v-model="register.politic.shown"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span class="font-weight-bold" v-bind="attrs" v-on="on"
                        >política de privacidad</span
                      >
                    </template>
                    <v-card>
                      <v-card-title>Política de privacidad</v-card-title>
                      <v-card-text
                        >Esto es la política de privacidad</v-card-text
                      >
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          @click="
                            register.politic.checbox = false;
                            register.politic.shown = false;
                          "
                          >Cancelar</v-btn
                        >
                        <v-btn
                          text
                          @click="
                            register.politic.checbox = true;
                            register.politic.shown = false;
                          "
                          color="primary"
                          >Aceptar</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </span>
              </template>
            </v-checkbox>
            <v-btn
              type="submit"
              large
              color="primary"
              block
              :disabled="register.loading"
              :loading="register.loading"
              >Crear cuenta</v-btn
            >
          </v-container>
        </v-form>
      </v-tab-item>
      <v-tab-item key="2">
        <v-form
          v-model="recover.validation"
          ref="recoverForm"
          @submit.prevent="Recover"
          class="pa-4"
        >
          <v-text-field
            v-model="recover.email"
            name="email"
            filled
            label="Correo electrónico"
            :rules="[rules.req, rules.email]"
          >
          </v-text-field>
          <small v-show="recover.retry"
            >¿No has recibido nada? Vuelve a intentarlo</small
          >
          <v-btn
            type="submit"
            large
            color="primary"
            block
            :loading="recover.loading"
            :disabled="recover.cooldown > 0"
          >
            {{
              recover.cooldown > 0
                ? `Por favor, espera ${recover.cooldown} segundos`
                : "Enviar correo de recuperación"
            }}</v-btn
          >
        </v-form>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { min, email, req, containsNum } from "@/utils/validationRules.js";
import axios from "redaxios";

export default {
  data() {
    return {
      tab: null,
      date_active: false,
      rules: {
        min: (v) => min(v, 8),
        email: email,
        req: req,
        containsNum: containsNum,
      },
      login: {
        email: "",
        password: "",
        validation: false,
        error: false,
        showPass: false,
        cuentaDesactivada: false,
      },
      register: {
        email: "",
        username: "",
        born: "",
        password: "",
        password2: "",
        showPass1: false,
        showPass2: false,
        politic: {
          shown: false,
          checbox: false,
        },
        validation: false,
        enUso: false,
        loading: false,
      },
      recover: {
        active: false,
        email: "",
        validation: false,
        cooldown: 0,
        retry: false,
        loading: false,
      },
    };
  },
  methods: {
    Login() {
      if (this.$refs.loginForm.validate()) {
        this.login.error = false;
        this.$store
          .dispatch("login", {
            email: this.login.email,
            password: this.login.password,
          })
          .then(() => {
            this.$router.go(-1);
            this.$root.$emit("snack", "Se ha iniciado sesión");
          })
          .catch((error) => {
            if (error.response.status == 403) {
              this.login.cuentaDesactivada = true;
              this.login.password = "";
            } else {
              this.login.error = true;
              this.login.password = "";
            }
            this.$root.$emit("snack", "No se ha podido iniciar sesión");
          });
      }
    },
    Register() {
      this.register.loading = true;
      if (this.$refs.registerForm.validate() && this.register.enUso == false) {
        this.$store
          .dispatch("register", {
            email: this.register.email,
            username: this.register.username,
            nacimiento: this.register.born,
            password: this.register.password,
          })
          .then(() => {
            this.$router.push({ name: "Home" });
            this.$root.$emit("snack", "Se ha creado la cuenta con éxito");
          })
          .catch(() => {
            this.$root.$emit("snack", "No se ha podido realizar el registro");
          });
      }
      this.register.loading = false;
    },
    Recover() {
      this.recover.loading = true;
      let formData = new FormData();
      formData.append("accion", "enviar");
      formData.append("correo", this.recover.email);

      axios({
        method: "post",
        url: `${process.env.VUE_APP_API_URL}/recuperarclave.php`,
        data: formData,
      })
        .then(() => {
          this.$root.$emit(
            "snack",
            "Se ha enviado el correo, mira tu bandeja de entrada"
          );
        })
        .catch((e) => {
          console.error(e);
          this.$root.$emit(
            "snack",
            "No se ha podido enviar el correo, intentalo más tarde"
          );
        });

      setTimeout(() => (this.recover.loading = false), 4000);

      this.recover.cooldown = 14;
      this.recover.retry = true;
      this.cooldown();
    },
    cooldown() {
      if (this.recover.cooldown > 0) {
        setTimeout(() => {
          this.recover.cooldown -= 1;
          this.cooldown();
        }, 1000);
      }
    },
    correoUnico() {
      axios({
        method: "POST",
        url: `${process.env.VUE_APP_API_URL}/register.php`,
        data: {
          correo: this.register.email,
        },
      })
        .then(() => (this.register.enUso = false))
        .catch(() => {
          this.register.enUso = true;
        });
    },
  },
  watch: {
    tab(n) {
      if (n != 2) this.recover.active = false;
    },
  },
};
</script>